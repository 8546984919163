<template>
	<div class="creatGoodDetails" v-loading="pageLoading">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
			<!-- 基本信息 -->
			<div class="baseInformtion">
				<div class="infortion">基本信息</div>
				<el-form-item label="商品类型">
					<el-radio @change="radioChange" :disabled='!!$route.query.id' v-model="ruleForm.isOverseas" :label="false">实物商品（物流发货）</el-radio>
					<el-radio @change="radioChange" :disabled='!!$route.query.id' v-model="ruleForm.isOverseas" :label="true">海淘商品（买家需必填身份证）</el-radio>
				</el-form-item>
				<el-form-item label="是否要身份证图片" v-if="ruleForm.isOverseas">
					<el-radio :disabled='!!$route.query.id' v-model="ruleForm.isNeedCardImg" :label="true">是</el-radio>
					<el-radio :disabled='!!$route.query.id' v-model="ruleForm.isNeedCardImg" :label="false">否</el-radio>
				</el-form-item>
				<el-form-item label="商品编码" prop="goodsCode" v-if="ruleForm.goodsCode">
					<el-input v-model="ruleForm.goodsCode" size="small" style="width:350px" disabled></el-input>
					<div class="textColor">管理商品编码，可帮助你在商品列表中快速查询商品</div>
				</el-form-item>

				<el-form-item label="商品条码" prop="barCode" v-if="goodsSpecValueList.length == 0">
					<el-input v-model="ruleForm.barCode" :disabled="isEdit==0" size="small" style="width:350px"></el-input>
				</el-form-item>

				<el-form-item label="商品名称" prop="goodsName">
					<el-input v-model="ruleForm.goodsName" :disabled="isEdit==0" style="width:500px" maxlength="60" placeholder="最多输入60个字"></el-input>
				</el-form-item>
				<el-form-item label="广告语" prop="advertisementWords">
					<el-input type="textarea" :rows="2" :disabled="isEdit==0" v-model="ruleForm.advertisementWords" style="width:500px" maxlength="300"
					placeholder="最多输入300个字"></el-input>
					<div class="textColor">商品卖点将在商品详情页显示，最多允许输入300个字</div>
				</el-form-item>

				<el-form-item label="商品分类" prop="categoryId">
					<el-cascader v-model="ruleForm.categoryId" :disabled="isEdit==0" :options="CategorySelectItemList" :props="{ value: 'id', label: 'categoryName' ,children:'childCategoryList'}"
					children="childCategoryList" label="categoryName" value="id" style="width:300px;"></el-cascader>
                    <div class="textColor" v-if="learnCategoryId">
                        <span>来源供应商设置的分类为：</span>
                        <span v-for="Category in CategorySelectItemList" :key="Category.id"> <span v-if="Category.id == learnCategoryId">{{Category.categoryName}}</span></span>
                    </div>
				</el-form-item>

				<el-form-item label="库存单位">
					<el-input v-model="ruleForm.unit" :disabled="isEdit==0" size="small" style="width:350px" clearable placeholder="请输入商品单位"></el-input>
				</el-form-item>

				<el-form-item label="商品供应商" prop="supplierId">
					<el-select v-model="ruleForm.supplierId" :disabled="isEdit==0" placeholder="请选择商品供应商" @change="changeSupplierId()" filterable clearable>
						<el-option v-for="item in productList" :key="item.id" :label="item.supplierName" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
                <el-form-item label="品牌商" prop="brandId">
					<el-select v-model="ruleForm.brandId" :disabled="isEdit==0" placeholder="请选择品牌商"  @change="changeBrandId()" filterable clearable>
						<el-option v-for="item in showBrandList" :key="item.id" :label="item.brandName" :value="item.id"></el-option>
					</el-select>
				</el-form-item>

				<div class="filter-container">
					<el-form-item label="商品图片" prop="goodsImgUrls">
						<!-- <div class="filter-item" style="width:100px;text-align: right;vertical-align: top;"><span class="tablered">*</span>商品图片</div> -->
						<div class="filter-item" style="vertical-align: top;">
							<upload-img :disabled="isEdit==0" @transPicture="transPictureFun" :AttachedImgUrls="ruleForm.goodsImgUrls" :Type="1"></upload-img>
							<div class="textColor" style="margin-top:20px">建议尺寸：800*800像素，拖拽图片可以调整顺序，排在第1位的图片将作为商品主图，用于显示在商品列表或分享等；图片最多上传10张</div>
						</div>
					</el-form-item>
				</div>

				<el-form-item label="主图视频">
					<div class="videoBox">
						<el-button type="text" style="font-size: 12px;" @click="deleteVideo" :disabled="!ruleForm.mainVideoUrl || isEdit==0">删除视频</el-button>
						<el-upload :disabled="isEdit==0" :action="videoApi" :show-file-list="false" :on-success="handleVideoSuccess" list-type="picture-card"
						:before-upload="beforeUploadVideo" :on-progress="uploadVideoProcess">
							<video v-if="ruleForm.mainVideoUrl" :src="ruleForm.mainVideoUrl" class="avatar" controls="controls" style="width:148px;height:148px">您的浏览器不支持视频播放</video>
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							<div slot="tip" class="el-upload__tip">建议30S以内视频，建议使用1：1或16：9比例视频；视频大小请控制在30M以内</div>
						</el-upload>
					</div>
				</el-form-item>
				<el-form-item label="加入到购物车">
					<el-radio-group :disabled="isEdit==0 || ruleForm.saleGoodsType === 4" v-model="ruleForm.isEnableShoppingCard">
						<el-radio :label="true">开启</el-radio>
						<el-radio :label="false">关闭</el-radio>
					</el-radio-group>
					<p class="textColor">关闭加入购物车后，商品只能直接购买，不可在购物车内结算</p>
				</el-form-item>
				<el-form-item label="其他设置">
                    <div style="display:flex;">
                        <div>
                            <el-checkbox :disabled="isEdit==0" v-model="ruleForm.isHide">商品隐藏</el-checkbox>
                            <div style="color: #f00;">设置后商品将不会展示在店铺内</div>
                        </div>
                        <div style="margin-left:20px;">
                            <el-checkbox v-model="ruleForm.isShare">允许分享</el-checkbox>
                            <div style="color: #f00;">取消勾选后，分享好友功能将禁止使用；</div>
                        </div>
                    </div>
				</el-form-item>
				<el-form-item label="售后服务">
					<el-checkbox :disabled="isEdit==0 || ruleForm.saleGoodsType === 4" v-model="ruleForm.afterSaleServiceSettings1">允许退款</el-checkbox>
					<el-checkbox :disabled="isEdit==0 || ruleForm.saleGoodsType === 4" v-model="ruleForm.afterSaleServiceSettings2">允许退款退货</el-checkbox>
					<el-checkbox :disabled="isEdit==0" v-model="ruleForm.afterSaleServiceSettings3">允许换货</el-checkbox>
				</el-form-item>
				<el-form-item label="商品标签">
					<el-checkbox :disabled="isEdit==0" v-model="tag.isSelect" v-for="tag in ruleForm.tags" :key="tag.id">{{tag.tagsName}}</el-checkbox>
				</el-form-item>
                <el-form-item label="服务承诺">
					<el-checkbox :disabled="isEdit==0" v-model="promise['promise'+index]" v-for="(promise,index) in promiseList" :key="index">{{promise.promiseSericeName}}</el-checkbox>
				</el-form-item>
                <!-- <el-form-item label="增值服务">
                    <el-radio-group :disabled="isEdit==0" v-model="ruleForm.appreciatSerice" >
						<el-radio :label="1">开启</el-radio>
						<el-radio :label="0">关闭</el-radio>
					</el-radio-group>
                    <el-form-item label="">
                        <el-radio-group :disabled="isEdit==0" v-model="ruleForm.appreciatSericeId" v-if="ruleForm.appreciatSerice">
                            <el-radio
                                :label="appreciat.id"
                                v-for="(appreciat,index) in appreciatList"
                                :key="index">
                                {{appreciat.appreciatName + '¥'+ appreciat.appreciatPrice}}
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>
				</el-form-item> -->
				<el-form-item label="运费补贴" v-if="ruleForm.appreciatSerice">
					<el-input v-model="ruleForm.subsidyMoney" :disabled="isEdit==0" style="width:200px" maxlength="5" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);" placeholder="请填写运费补贴"></el-input>
					<span style="margin-left: 10px;">元</span>
				</el-form-item>
			</div>

			<!-- 销售信息 -->
			<!-- 单品的 -->
			<div class="baseInformtion">
				<div class="infortion">销售信息</div>
				<el-form-item label="商品规格">
					<el-card class="box-card" style="width:80%;">
						<div class="card-body">
							<div v-for="(items,indexs) in goodsSpecValueList" :key="items.key">
								<div class="spec-bos" style="margin-bottom:20px;position:relative">
									<el-form-item label="规格名">
										<el-select :disabled="isEdit==0" v-model="items.specName" filterable clearable :allow-create="true" :default-first-option="true"
										@visible-change="inputFunction1" @change="brothSpece()">
											<el-option v-for="(item,index) in speceTitleData" :key="index" :value="item.specName" :label="item.specName"
											:disabled="item.disabled" style="width:200px"></el-option>
										</el-select>
										<el-radio :disabled="isEdit==0" v-model="items.hasImg" :label="true" style="margin-left:30px;" @change="changeHasImg(items,indexs)">选为带图片的规格</el-radio>
										<i class="el-icon-circle-close close-bosx1" v-show="items.closeShow" @click="closeFunction(indexs)"></i>
									</el-form-item>
								</div>

								<div class="speceBoxe" style="position: relative;">
									<el-form-item label="规格值" class="speceBos">
										<div class="filter-container">
											<!-- @click.capture="getSpeceval(items)" -->
											<div class="filter-item" style="position:relative;margin-right:20px;" v-for="(obj,specValueIndex) in items.specValues"
											:key="specValueIndex" :style="items.hasImg?'width:240px;':'width:200px;'">
												<el-select :disabled="isEdit==0" style="width:200px" v-model="obj.specValueName" filterable clearable :allow-create="true"
												:default-first-option="true" @visible-change="inputFunction(indexs)" @change="brothSpece()">
													<el-option v-for="(item,index) in specevalData " :key="index" :value="item.name" :label="item.name"
													:disabled="item.disabled" style="width:200px"></el-option>
												</el-select>

												<i class="el-icon-error close-bosx" style="cursor: pointer;" :style="items.hasImg?'right: 35px;':''" @click="closeFuns(items.specValues, specValueIndex)"></i>
												<!-- 规格图片 -->
												<div v-if="items.hasImg" class="close-bosx2" @click="showImages(indexs,specValueIndex)">
													<el-upload :disabled="isEdit==0" class="avatar-uploader" :action="imgApi" :show-file-list="false" :on-success="handleAvatarSuccess1"
													:before-upload="beforeAvatarUpload1">
														<img v-if="obj.imgUrl" :src="obj.imgUrl+'?x-oss-process=image/resize,m_fixed,h_80,w_80'" style="width:40px;height:40px"
														class="avatar" />
														<i v-else class="el-icon-plus" style="width:38px;height:27px;"></i>
													</el-upload>
												</div>
											</div>

											<div class="filter-item addSpece" @click="addSpece(items)" v-if="items.specValues.length < 30">添加规格值</div>
										</div>
									</el-form-item>
								</div>
							</div>
							<el-button :disabled="isEdit==0" size="small" @click="addSprces" v-if="goodsSpecValueList.length<5">添加规格</el-button>
						</div>
					</el-card>
				</el-form-item>

				<div v-if="goodsSpecValueList.length" class="specparentbox">
					<el-form-item label="规格明细">
						<el-table max-height="500" :data="ruleForm.showGoodsSpecList" border style="width:80%; margin-top: 20px" :row-key="keyFun">
							<el-table-column type="selection" width="55"></el-table-column>
							<el-table-column fixed :prop="goodsSpec.guid" :label="goodsSpec.specName" v-for="(goodsSpec,index) in this.goodsSpecValueList"
							:key="index"></el-table-column>

							<el-table-column label="销售价" width="100">
								<template slot-scope="scope">
									<el-form-item label-width="0" :inline-message="true">
										<el-input :disabled="isEdit==0" v-model="scope.row.salePrice" @input="inputChange(scope.row,'salePrice')" style="width:80px;"
										onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input>
									</el-form-item>
								</template>
							</el-table-column>

							<el-table-column prop="originalPrice" label="划线价" width="100">
								<template slot-scope="scope">
									<el-form-item label-width="0" :inline-message="true" prop="originalPrice">
										<el-input :disabled="isEdit==0" v-model="scope.row.originalPrice" @input="inputChange(scope.row,'originalPrice')" style="width:80px;"
										onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input>
									</el-form-item>
								</template>
							</el-table-column>

                            <el-table-column label="活跃值" width="100">
								<template slot-scope="scope">
									<el-form-item label-width="0" :inline-message="true">
										<el-input :disabled="isEdit==0" v-model="scope.row.growRate" @input="inputChange(scope.row,'growRate')" style="width:80px;"
										onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input>
									</el-form-item>
								</template>
							</el-table-column>

							<el-table-column prop="stock" label="库存" width="100">
								<template slot-scope="scope">
									<el-form-item label-width="0" :inline-message="true" prop="stock" :rules="rules.stock">
										<el-input :disabled="isEdit==0" v-model="scope.row.stock" size="small" @input="inputChange(scope.row,'stock')" style="width:80px"
										input="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')" @change="changeStock()"></el-input>
									</el-form-item>
								</template>
							</el-table-column>

							<el-table-column prop="skuCode" label="商品编码" width="180">
								<template slot-scope="scope">
									<el-input disabled maxlength="100" v-model="scope.row.skuCode" size="small" style="width:150px"></el-input>
								</template>
							</el-table-column>

							<el-table-column prop="barCode" label="商品条码" width="180">
								<template slot-scope="scope">
									<el-input :disabled="isEdit==0" maxlength="100" @input="inputChange(scope.row,'barCode')" v-model="scope.row.barCode" size="small"
									style="width:150px"></el-input>
								</template>
							</el-table-column>
						</el-table>
					</el-form-item>

					<el-form-item label>
						<div class="flex betwween" style="justify-content: space-between;width:80%;font-size: 16px;margin-top:-20px;border: 1px solid #eee;padding:5px 15px;border-top: 0;font-size: 14px;color: #606266;">
							<div>
								<span>批量设置：</span>
								<span v-if="bathPriceVisise">
									<!-- 改价格，供货价 -->
									<input :disabled="isEdit==0" v-model="bathNumbers" style="padding:0 10px;width:200px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
									type="number" @keydown="handleInput2" />
									<span style="margin:0 15px;cursor: pointer;" @click="sureBatchdata">确定</span>
									<span style="margin-right:15px;cursor: pointer;" @click="bathPriceVisise=false">取消</span>
								</span>
								<span v-if="!bathPriceVisise">
									<span class="priceNum" @click="bathPrice(1)">销售价</span>
									<span class="priceNum" @click="bathPrice(2)">划线价</span>
									<span class="priceNum" @click="bathPrice(4)">活跃值</span>
									<span class="priceNum" @click="bathPrice(3)">库存</span>
									<span class="priceNum" @click="bathPrice(5)">商品条码</span>
								</span>
							</div>
							<div>
								<el-pagination v-if="total" style="" @size-change="handleSizeChange" @current-change="handleCurrentChange"
								:current-page="pageNo" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
								:total="total"></el-pagination>
							</div>
						</div>

					</el-form-item>
				</div>

				<div>
					<el-form-item label="销售价" prop="salePrice" v-if="goodsSpecValueList.length == 0" :rules="rules.salePrice">
						<el-input :disabled="isEdit==0" v-model="ruleForm.salePrice" style="width:200px;" @input="inputChange(ruleForm,'salePrice')" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input>
					</el-form-item>

					<el-form-item label="划线价" prop="originalPrice" v-if="goodsSpecValueList.length == 0" :rules="[{required:true,message:'划线价不能为空',trigger: 'blur'}]">
						<el-input :disabled="isEdit==0" v-model="ruleForm.originalPrice" style="width:200px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input>
					</el-form-item>

					<el-form-item label="活跃值" prop="growRate" v-if="goodsSpecValueList.length == 0" :rules="[{required:true,message:'活跃值不能为空',trigger: 'blur'}]">
						<el-input :disabled="isEdit==0" v-model="ruleForm.growRate" style="width:200px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input>
					</el-form-item>

					<el-form-item label="虚拟销量">
						<el-input :disabled="isEdit==0" v-model="ruleForm.virtualSoldCount" style="width:150px" clearable input="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')"></el-input>
					</el-form-item>

					<el-form-item label="成本价">
						<el-input :disabled="isEdit==0" v-model="ruleForm.costPrice" style="width:200px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input>
					</el-form-item>

					<!-- 当是分销商品时库存不可编辑 -->
					<el-form-item label="总库存" prop="totalStock">
						<el-input v-model="ruleForm.totalStock" :disabled="goodsSpecValueList.length != 0 || isEdit==0" style="width:150px" clearable
						input="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')"></el-input>
					</el-form-item>
					<el-form-item label="库存预警" prop="warningStock">
						<el-input :disabled="isEdit==0" v-model="ruleForm.warningStock" type="number" style="width:150px;" input="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')"></el-input>
						<div class="textColor">设置最低库存预警值。当库存低于预警值时会在商家中心商品列表的库存预警页显示。0为不预警。</div>
					</el-form-item>
					<el-form-item label="库存显示">
						<el-radio :disabled="isEdit==0" v-model="ruleForm.isShowStock" :label="true">是</el-radio>
						<el-radio :disabled="isEdit==0" v-model="ruleForm.isShowStock" :label="false">否</el-radio>
					</el-form-item>
					<!-- <el-form-item label="库存计算方式">
						<el-radio v-model="ruleForm.stockReduceMethod" :label="1">下单减库存</el-radio>
						<el-radio v-model="ruleForm.stockReduceMethod" :label="2">支付减库存</el-radio>
					</el-form-item> -->
					<el-form-item label="销量显示">
						<el-radio :disabled="isEdit==0" v-model="ruleForm.isShowSales" :label="1">是</el-radio>
						<el-radio :disabled="isEdit==0" v-model="ruleForm.isShowSales" :label="0">否</el-radio>
					</el-form-item>
					<el-form-item label="上架时间">
						<el-radio-group :disabled="isEdit==0" v-model="ruleForm.startSaleTimeType">
							<el-form-item label-width="0">
								<el-radio :label="1">立即上架售卖</el-radio>
							</el-form-item>
							<el-form-item label-width="0" prop="timingSaleTime" :rules="ruleForm.startSaleTimeType==2 ?[{required:true,message:' ',trigger: 'blur'}]:[]">
								<el-radio :label="2">自定义上架时间</el-radio>
								<el-date-picker :disabled="isEdit==0" v-model="ruleForm.timingSaleTime" type="datetime" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss"
								v-if="ruleForm.startSaleTimeType== 2"></el-date-picker>
							</el-form-item>
							<el-form-item label-width="0">
								<el-radio :label="3">暂不售卖，放入仓库</el-radio>
							</el-form-item>
						</el-radio-group>
					</el-form-item>

					<el-form-item label="下架时间" prop="timingOffTime" :rules="ruleForm.endSaleTimeType?[{required:true,message:' ',trigger: 'blur'}]:[]">
						<el-checkbox :disabled="isEdit==0" v-model="ruleForm.endSaleTimeType">开启</el-checkbox>
						<el-date-picker :disabled="isEdit==0" v-model="ruleForm.timingOffTime" type="datetime" placeholder="选择日期" v-if="ruleForm.endSaleTimeType"
						value-format="yyyy-MM-dd HH:mm:ss" style="margin-left:20px;"></el-date-picker>
					</el-form-item>

					<el-form-item label="每人限购">
						<el-radio-group :disabled="isEdit==0" v-model="ruleForm.saleLimitType">
							<el-form-item label-width="0">
								<el-radio :label="1">不限购</el-radio>
							</el-form-item>
							<el-form-item label-width="0" prop="timeLimitTime" :rules="ruleForm.saleLimitType == 2?[{required:true,message:' ',trigger: 'blur'}]:[]">
								<el-radio :label="2">限时限购</el-radio>
								<el-date-picker :disabled="isEdit==0" v-model="ruleForm.timeLimitTime" type="datetimerange" range-separator="至" start-placeholder="开始日期"
								end-placeholder="结束日期" v-if="ruleForm.saleLimitType == 2" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
							</el-form-item>
							<el-form-item label-width="0" prop="saleLimitQuantity" :rules="ruleForm.saleLimitType == 3?[{required:true,message:' ',trigger: 'blur'}]:[]">
								<el-radio :label="3">永久限购</el-radio>
								<el-input :disabled="isEdit==0" v-model="ruleForm.saleLimitQuantity" style="width:200px;" input="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')"
								v-if="ruleForm.saleLimitType == 3"></el-input>
							</el-form-item>
						</el-radio-group>
					</el-form-item>

					<el-form-item label="快递发货">
						<el-radio-group :disabled="isEdit==0" v-model="ruleForm.freightCalMethod">
							<el-form-item label-width="0" prop="freightPrice">
								<el-radio :label="1">
									全国统一运费
									<el-input :disabled="isEdit==0" v-model="ruleForm.freightPrice" style="width:200px;" :onkeyup="ruleForm.freightPrice = publicFormatNum(ruleForm.freightPrice,3)"></el-input>元
								</el-radio>
							</el-form-item>

							<el-form-item label-width="0" :prop="ruleForm.freightCalMethod==2?'freightTemplateId':''">
								<el-radio :label="2" style="margin-top:10px">
									使用运费模板
									<el-select :disabled="isEdit==0" v-model="ruleForm.freightTemplateId" placeholder="请选择运费模板" filterable clearable>
										<el-option v-for="item in FreightTemplateIdList " :key="item.id" :value="item.id" :label="item.templateName"></el-option>
									</el-select>
								</el-radio>
							</el-form-item>
                            <div class="textColor" style="margin-top:10px;" v-if="postageRemark">运费说明：{{postageRemark}}</div>
						</el-radio-group>
					</el-form-item>
				</div>
			</div>
			<!-- 收益与提成 -->
			<div class="baseInformtion">
				<div class="infortion">收益与提成</div>
				<el-form-item label="商品类型">
					<el-radio @change="goodsTypeRadioChange" :disabled='isEdit==0' v-model="ruleForm.saleGoodsType" :label="1">常规商品</el-radio>
					<el-radio @change="goodsTypeRadioChange" :disabled='isEdit==0' v-model="ruleForm.saleGoodsType" :label="2">直播间特供</el-radio>
					<el-radio @change="goodsTypeRadioChange" :disabled='isEdit==0' v-model="ruleForm.saleGoodsType" :label="3">24H模式商品</el-radio>
					<el-radio @change="goodsTypeRadioChange" :disabled='isEdit==0' v-model="ruleForm.saleGoodsType" :label="4">换货商品</el-radio>
				</el-form-item>
                <el-form-item label="商品分账类型" @change='changeLAType'>
					<el-radio :disabled="isEdit==0" v-model="ruleForm.ledgerAccountType" :label="1">A类</el-radio>
					<el-radio :disabled="isEdit==0" v-model="ruleForm.ledgerAccountType" :label="2">B类</el-radio>
					<el-radio :disabled="isEdit==0" v-model="ruleForm.ledgerAccountType" :label="3">C类</el-radio>
				</el-form-item>
				<el-form-item label="是否计入高管奖励">
					<el-radio :disabled="isEdit==0 || ruleForm.saleGoodsType === 4" v-model="ruleForm.familyAwardFlag" :label="1">是</el-radio>
					<el-radio :disabled="isEdit==0 || ruleForm.saleGoodsType === 4" v-model="ruleForm.familyAwardFlag" :label="0">否</el-radio>
				</el-form-item>
				<el-form-item label="是否计入伯乐奖励" >
					<el-radio :disabled="isEdit==0 || ruleForm.saleGoodsType === 4" v-model="ruleForm.boleAwardFlag" :label="1">是</el-radio>
					<el-radio :disabled="isEdit==0 || ruleForm.saleGoodsType === 4" v-model="ruleForm.boleAwardFlag" :label="0">否</el-radio>
                    <span style="color:#999;">伯乐奖预设比例：{{formatGrowRate((ruleForm.ledgerAccountType == 1 ? boleAwardConfigA.s  : ruleForm.ledgerAccountType == 2 ? boleAwardConfigB.s : boleAwardConfigC.s) * 100)}}%</span>
				</el-form-item>
				<el-form-item label="是否计入平推奖励">
					<el-radio :disabled="isEdit==0 || ruleForm.saleGoodsType === 4" v-model="ruleForm.recommendAwardFlag" :label="1">是</el-radio>
					<el-radio :disabled="isEdit==0 || ruleForm.saleGoodsType === 4" v-model="ruleForm.recommendAwardFlag" :label="0">否</el-radio>
                    <span style="color:#999;">平推奖预设比例：
                        {{ruleForm.pusherProfitConfigs[4].pusherLabel}}享{{formatGrowRate((ruleForm.ledgerAccountType == 1 ? recommendAwardConfigA.s  : ruleForm.ledgerAccountType == 2 ? recommendAwardConfigB.s : recommendAwardConfigC.s) * 100)}}%,
                        {{ruleForm.pusherProfitConfigs[3].pusherLabel}}享{{formatGrowRate((ruleForm.ledgerAccountType == 1 ? recommendAwardConfigA.a  : ruleForm.ledgerAccountType == 2 ? recommendAwardConfigB.a : recommendAwardConfigC.a) * 100)}}%,
                        {{ruleForm.pusherProfitConfigs[2].pusherLabel}}享{{formatGrowRate((ruleForm.ledgerAccountType == 1 ? recommendAwardConfigA.b  : ruleForm.ledgerAccountType == 2 ? recommendAwardConfigB.b : recommendAwardConfigC.b) * 100)}}%</span>
				</el-form-item>
				<el-form-item label="推手收益" v-if="ruleForm.pusherProfitConfigs.length">
					<el-table :data="ruleForm.ledgerAccountType == 1 ? ruleForm.pusherProfitConfigsA : ruleForm.ledgerAccountType == 2 ? ruleForm.pusherProfitConfigsB : ruleForm.pusherProfitConfigsC" style="width:600px" :loading="loading">
						<el-table-column prop="pusherLabel" label="推手等级"></el-table-column>
						<el-table-column prop="name" label="自营销售收益比">
							<template slot-scope="scope">
								<el-form-item label-width="0" :inline-message="true" :prop="'pusherProfitConfigs.' + scope.$index + '.discountRate'"
								:rules="rules.discountRate">
									<!-- <span style="margin:0 7px">*</span> -->
									<el-input :disabled="isEdit==0" placeholder="0" v-model="scope.row.discountRate" style="width:140px;" :onkeyup="scope.row.discountRate = publicFormatNum(scope.row.discountRate, 2)"></el-input>
								</el-form-item>
							</template>
						</el-table-column>
					</el-table>
				</el-form-item>
			</div>
            <!-- 解冻服务 -->
            <div class="baseInformtion">
				<div class="infortion">解冻服务</div>
                <div class="flex">
                    <div>
                        <div class="">解冻库存金</div>
                        <el-form-item label="解冻方式" label-width="60" style="margin-bottom: 0px;width:500px;">
                            <el-radio :disabled="isEdit==0 || ruleForm.saleGoodsType === 4" v-model="ruleForm.unfrozenStockBalanceMethod" :label="2">自定义方式</el-radio>
                            <el-radio :disabled="isEdit==0 || ruleForm.saleGoodsType === 4" v-model="ruleForm.unfrozenStockBalanceMethod" :label="1">按照蒂克莉等级解冻</el-radio>
                            <el-radio :disabled="isEdit==0 || ruleForm.saleGoodsType === 4" v-model="ruleForm.unfrozenStockBalanceMethod" :label="0">不参与解冻</el-radio>
                        </el-form-item>
                        <el-form-item label="" label-width="0" v-if="ruleForm.unfrozenStockBalanceMethod == 2">
                            <div style="color:#F56C6C;">设置为0时，该商品不解保证金</div>
                            <el-table :data="ruleForm.ledgerAccountType == 1 ? ruleForm.USBRConfigA : ruleForm.ledgerAccountType == 2 ? ruleForm.USBRConfigB : ruleForm.USBRConfigC" style="width:500px" :loading="loading">
                                <el-table-column prop="pusherLabel" label="推手等级"></el-table-column>
                                <el-table-column prop="name" label="库存金解冻比">
                                    <template slot-scope="scope">
                                        <el-form-item label-width="0" :inline-message="true" :prop="'pusherProfitConfigs.' + scope.$index + '.discountRate'"
                                        :rules="rules.discountRate">
                                            <!-- <span style="margin:0 7px">*</span> -->
                                            <el-input :disabled="isEdit==0" placeholder="0" v-model="scope.row.discountRate" style="width:80px;" :onkeyup="scope.row.discountRate = publicFormatNum(scope.row.discountRate,2)"></el-input>
                                        </el-form-item>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                        <el-form-item label="" label-width="0" v-else-if="ruleForm.unfrozenStockBalanceMethod == 1">
                            <div style="color:#F56C6C;">使用蒂可莉里由库存转为库存金时的兑换折扣值</div>
                        </el-form-item>
                        <el-form-item label="" label-width="0" v-else>
                            <div style="color:#F56C6C;">该商品不参与解冻库存金</div>
                        </el-form-item>
                    </div>
                    <div style="margin-left:30px;">
                        <div>解冻保证金</div>
                        <el-form-item label="解冻比例" label-width="60" style="margin-bottom: 0px;width:500px;">
                            <el-radio :disabled="isEdit==0 || ruleForm.saleGoodsType === 4" v-model="ruleForm.unfrozenDepositMethod" :label="1">默认解冻方式</el-radio>
                            <el-radio :disabled="isEdit==0 || ruleForm.saleGoodsType === 4" v-model="ruleForm.unfrozenDepositMethod" :label="0">不参与解冻</el-radio>
                        </el-form-item>
                        <el-form-item label="" label-width="0" v-if="ruleForm.unfrozenDepositMethod == 1">
                            <div style="color:#F56C6C;">设置为0时，该商品不解保证金</div>
                            <el-table :data="ruleForm.ledgerAccountType == 1 ? ruleForm.UDRConfigA : ruleForm.ledgerAccountType == 2 ? ruleForm.UDRConfigB : ruleForm.UDRConfigC" style="width:500px" :loading="loading">
                                <el-table-column prop="pusherLabel" label="推手等级"></el-table-column>
                                <el-table-column prop="name" label="保证金解冻比">
                                    <template slot-scope="scope">
                                        <el-form-item label-width="0" :inline-message="true" :prop="'pusherProfitConfigs.' + scope.$index + '.discountRate'"
                                        :rules="rules.discountRate">
                                            <!-- <span style="margin:0 7px">*</span> -->
                                            <el-input :disabled="isEdit==0" placeholder="0" v-model="scope.row.discountRate" style="width:80px;" :onkeyup="scope.row.discountRate = publicFormatNum(scope.row.discountRate, 2)"></el-input>
                                        </el-form-item>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                        <el-form-item label="" label-width="0" v-else>
                            <div style="color:#F56C6C;">该商品不参与解冻保证金</div>
                        </el-form-item>
                    </div>
                </div>
			</div>

			<!-- 商品详情 -->
			<div class="baseInformtion" style="margin-bottom: 150px;">
				<div class="infortion">商品详情</div>
				<div class="baseBottom-box" style="margin-bottom:150px;margin-left:150px">
					<!-- max-height:700px; -->
					<quill-editor ref="myTextEditor" v-model="ruleForm.goodsDesc" :options="quillOption" style="width:80%;min-height: 300px;height:650px;"></quill-editor>
				</div>
			</div>

			<!-- 底部保存 -->
			<div class="bottom-save-btn">
				<el-button style="width:240px" @click="canselGoods">取消</el-button>

				<buttonPermissions :datas="'editGoodDetails'" v-if="$route.query.id > 0">
					<el-button v-if="isEdit==1" style="width:240px;margin:0 10px 0 20px" @click="submitForm()" :loading="loading">保存</el-button>
				</buttonPermissions>
				<buttonPermissions :datas="'creatGoodDetails'" v-else>
					<el-button style="width:240px;margin:0 10px 0 20px" @click="submitForm()" :loading="loading">保存</el-button>
				</buttonPermissions>
			</div>
		</el-form>
	</div>
</template>

<script>
	import {
		supplierGetList,
		findBrandPage,
		getSpecList,
		productSave,
		productEdit,
		productInfo,
		freightTemplateList,
        categoryList,
        getPromiseList,
        getAppreciatList,
        listTags
	} from '@/api/goods'
    import { getAwardConfigSetting } from '@/api/setting';
	import config from '@/config/index'
	import {
		quillEditor
	} from 'vue-quill-editor'
	import uploadImg from '@/components/uploadImgs/uploadPicture';
	import quillConfig from '@/components/quill-config.js'
	import selectProduct from '@/components/SelectMulProduce/SelectProduce.vue'
	import buttonPermissions from '@/components/buttonPermissions';
	// import util from '@/utils/util';

	export default {
		components: {
			quillEditor,
			uploadImg,
			selectProduct,
			buttonPermissions
		},
		data() {
			var checkUniformFreight = (rule, value, callback) => {
				console.log(value == 100000000, 999)
				if ((0 < value && value <= 100000000) || value === 0 || value === '0') {
					return callback();
				} else if (value > 100000000) {
					return callback(new Error('数值超出限制，请重新设置'));
				} else {
					return callback(new Error('请设置商品运费'));
				}
			};

			var checkFreightTemplateId = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请选择运费模板'));
				} else {
					return callback();
				}
			};

			var checkSellRewardMoney = (rule, value, callback) => {
				if (value > 100000000) {
					return callback(new Error('数值超出限制，请重新设置'));
				} else {
					return callback();
				}
			};

			var singleStockCheck = (rule, value, callback) => {
				if (!value && value !== 0) {
					return callback(new Error('库存最小为0，整数'));
				} else {
					return callback();
				}
			};
			//管理奖不能大于1，不能小于0
			var checkManageProfitConfig = (rule, value, callback) => {
				console.log(value);
				if (!value && value !== 0) {
					return callback(new Error('管理奖最小为0！'));
				} else if (value > 100) {
					return callback(new Error('管理奖最大为100%！'));
				} else {
					return callback();
				}
			};
			return {
				pageLoading: false,
				value: [],
				batchType: 0, //批量修改类型
				bathPriceVisise: false, //批量修改开关
				bathNumbers: null, //批量修改的值
				productList: [], //商品供应商数据
				brandList: [], //品牌数据
				showBrandList: [], //展示的品牌数据
				quillOption: quillConfig, //富文本
				FreightTemplateIdList: [], //运费模板数据
				CategorySelectItemList: [], //商品分类数据

				ruleForm: {
					isEnableShoppingCard: true,
					barCode: null,
					pusherProfitConfigs: [
                        {
							pusherLabel: '注册用户',
							pusherLevel: '0',
							discountRate: null,
						},
						{
							pusherLabel: 'C级',
							pusherLevel: '4',
							discountRate: null,
						},
						{
							pusherLabel: 'B级',
							pusherLevel: '3',
							discountRate: null,
						},
						{
							pusherLabel: 'A级',
							pusherLevel: '2',
							discountRate: null,
						},
						{
							pusherLabel: 'S级',
							pusherLevel: '1',
							discountRate: null,
						},
					],
                    pusherProfitConfigsA:[],    //A类默认
                    pusherProfitConfigsB:[],    //B类默认
                    pusherProfitConfigsC:[],    //C类默认

                    unfrozenStockBalanceMethod:1,   //解冻保证金方式 2 自定义 1 按DKL等级解冻 0 不参与解冻
                    USBRConfigA:[],  //解冻库存金比率
                    USBRConfigB:[],  //解冻库存金比率
                    USBRConfigC:[],  //解冻库存金比率

                    unfrozenDepositMethod:1,    //解冻库存金方式 1 默认 0 不参与解冻
                    UDRConfigA:[],   //解冻保证金比率
                    UDRConfigB:[],   //解冻保证金比率
                    UDRConfigC:[],   //解冻保证金比率


					goodsCode: '',
					goodsName: '',
					advertisementWords: '',
					goodsImgUrls: [],
					mainVideoUrl: '',
					supplierId: '',
					brandId: '',    //品牌ID
					categoryId: [],
					originalPrice: null,
					freightCalMethod: 1,
					freightTemplateId: null,
					goodsDesc: null,
					salePrice: null,
					totalStock: null,
					warningStock: 0,
					costPrice: '', //成本价
                    growRate:'',    //活跃值
					isShowStock: false, //库存是否显示
					isShowSales: 1, //销量是否显示
					stockReduceMethod: 1, //库存计算方式，1下单减库存，2支付减库存
					startSaleTimeType: 1, //上架时间选择，1立即上架售卖，2自定义上架时间，3暂不售卖。
					timingSaleTime: '', //定时开售 定时开售结束时间

					endSaleTimeType: false, //是否开启自动下架
					timingOffTime: '', //自动下架时间

					saleLimitType: 1, //限购类型， 1 不限购 2 限时限购 3 永久限购
					timeLimitTime: '', //限时限购时间
					saleLimitQuantity: '', //永久限购数量
					afterSaleServiceSettings1: true, //是否允许退款
					afterSaleServiceSettings2: true, //是否允许退款退货
					afterSaleServiceSettings3: false, //允许换货
					isOverseas: false, //是否海外商品
					isNeedCardImg: false, //海外商品购买时是否需要身份证图片
					saleGoodsType: 1, //商品类型 1普通商品 2直播特供商品 3：24H模式商品
					isHide:false,//是否隐藏
					isShare:true,//是否允许分享
                    tags:[], //标签
					freightPrice: 0, //全国统一运费
					stock: '', //库存
					goodsSpecList: [], //规格数据
					showGoodsSpecList: [], //显示的规格数据
					copyGoodsSpecList: [], //保存一个当前数据
					unit: '', //库存单位
					virtualSoldCount: 0, //虚拟销量

					recommendAwardFlag: 1, //是否计算平推奖 0否 1是
					familyAwardFlag: 1, //是否计算高管奖 0否 1是
					boleAwardFlag: 1, //是否计算伯乐奖 0否 1是
                    manageProfitConfig: 3, //商品在此订单详情的管理奖励配置
                    appreciatSerice: 0,     //是否开启增值服务
                    appreciatSericeId:'',     //增值服务ID
					subsidyMoney:8,//运费补贴
					ledgerAccountType:1,//商品分账类型
				},
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_IMG,
				videoApi: config.UPLOAD_VIDEO,
				rules: {
					goodsName: [{
							required: true,
							message: '请输入商品名称',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 100,
							message: '长度在 1 到 100 个字符',
							trigger: 'blur'
						}
					],
					categoryId: [{
						required: true,
						message: '请选择分类',
						trigger: 'change'
					}, ],
					supplierId: [{
						required: true,
						message: '请选择供应商',
						trigger: 'change'
					}, ],
                    brandId: [{
						required: true,
						message: '请选择品牌商',
						trigger: 'change'
					}, ],
					salePrice: [{
						required: true,
						message: '请输入价格',
						trigger: 'blur'
					}],
					totalStock: [{
						required: true,
						validator: singleStockCheck,
						trigger: 'blur'
					}],
					goodsSale: [{
						min: 1,
						max: 300,
						message: '长度在 1 到 300 个字符',
						trigger: 'blur'
					}],
					goodsImgUrls: [{
						type: 'array',
						required: true,
						message: '商品图片至少上传1张',
						trigger: 'change'
					}],
					freightPrice: [{
						validator: checkUniformFreight,
						trigger: 'change'
					}],
					freightTemplateId: [{
						validator: checkFreightTemplateId,
						trigger: 'change'
					}],
					discountRate: {
						validator: checkSellRewardMoney,
						trigger: 'blur'
					},
					manageProfitConfig: {
						validator: checkManageProfitConfig,
						trigger: 'blur'
					},
				},
				goodsSpecValueList: [],
				speceTitleData: [],
				specevalData: [],
				loading: false,
				total: 0, //规格列表数据总数
				pageNo: 1, //规格列表页数
                pageSize: 10, //规格列表每页数量
                promiseList:[], //服务承诺列表
                appreciatList:[],   //增值服务列表
                isEdit:1,//是否是编辑 1编辑  0 查看
                postageRemark:'',  //运费说明
                learnCategoryId:'', //参考的一级类目

				boleAwardConfigA: {s: 0},    //伯乐奖励
				boleAwardConfigB: {s: 0},    //伯乐奖励
				boleAwardConfigC: {s: 0},    //伯乐奖励
				recommendAwardConfigA: {s: 0, a: 0, b: 0},   //推荐将比例
				recommendAwardConfigB: {s: 0, a: 0, b: 0},   //推荐将比例
				recommendAwardConfigC: {s: 0, a: 0, b: 0},   //推荐将比例

				liveGoodsConfig: [
					{name: 'familyAwardFlag', val1: '', val2: 1, val3: 0, val4: 0}, //是否计入伯乐奖励
					{name: 'boleAwardFlag', val1: '', val2: 1, val3: 0, val4: 0}, //是否计入高管奖励
					{name: 'recommendAwardFlag', val1: '', val2: 0, val3: 0, val4: 0}, //是否计入平推奖励
					{name: 'unfrozenDepositMethod', val1: '', val2: 0, val3: 0, val4: 0}, //是否解冻保证金
					{name: 'unfrozenStockBalanceMethod', val1: '', val2: 0, val3: 0, val4: 0}, //是否解冻库存金
				],
			}
		},
		beforeMount() {
			this.isEdit = this.$route.query.isEdit || 1;
			this.getInfor();
			this.getSpeceData()
		},
		mounted() {
			this.$refs.myTextEditor.quill.enable(false);
            //处理等级相关奖励配置
            this.LEVELLIST.map(item =>{
                this.ruleForm.pusherProfitConfigs.map(item1 =>{
                    if (item.pusherLevel == item1.pusherLevel) {
                        item1.pusherLabel = item.pusherLabel;
                    }
                })
            })
		},
		methods: {
			deleteVideo() {
				this.ruleForm.mainVideoUrl = ''
			},
			keyFun(row) {
				return row.uid
			},
			//修改库存后触发
			changeStock() {
				let totalStock = 0;
				this.ruleForm.goodsSpecList.map(item => {
					if (item.stock && Number(item.stock)) {
						totalStock += Number(item.stock);
					}
				})
				this.ruleForm.totalStock = totalStock;
			},
			// 确认批量
			sureBatchdata() {
				if (!this.bathNumbers) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '请输入批量设置的数值!'
					});
					return
				}
				if (this.bathNumbers > 100000000) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '数值超出限制，请重新设置!'
					});
					return
				}

				if (this.batchType == 1) {
                    let salePrice = this.bathNumbers / 100;
					this.ruleForm.goodsSpecList.map(item => {
						item.salePrice = this.bathNumbers;
                        item.growRate = this.formatGrowRate(item.salePrice / 100);
						return item
					})
					this.ruleForm.showGoodsSpecList.map(item => {
						item.salePrice = this.bathNumbers;
                        item.growRate = this.formatGrowRate(item.salePrice / 100);
						return item
					})
				} else if (this.batchType == 2) {
					this.ruleForm.goodsSpecList.map(item => {
						item.originalPrice = this.bathNumbers;
						return item
					})
					this.ruleForm.showGoodsSpecList.map(item => {
						item.originalPrice = this.bathNumbers;
						return item
					})
				} else if (this.batchType == 3) {
					//库存
					this.ruleForm.goodsSpecList.map(item => {
						item.stock = this.bathNumbers;
						return item
					})
					this.ruleForm.showGoodsSpecList.map(item => {
						item.stock = this.bathNumbers;
						return item
					})
					this.changeStock();
				} else if (this.batchType == 4) {
					//活跃值
					this.ruleForm.goodsSpecList.map(item => {
						item.growRate = this.bathNumbers;
						return item
					})
					this.ruleForm.showGoodsSpecList.map(item => {
						item.growRate = this.bathNumbers;
						return item
					})
					this.changeStock();
				} else if (this.batchType == 5) {
					// 设置重量
					this.ruleForm.goodsSpecList.map(item => {
						item.barCode = this.bathNumbers + '';
						return item
					})
					this.ruleForm.showGoodsSpecList.map(item => {
						item.barCode = this.bathNumbers;
						return item
					})
				}
				this.bathPriceVisise = false;
			},
			// 批量修改价格，供货价
			bathPrice(type) {
				this.bathNumbers = null;
				this.batchType = type;
				this.bathPriceVisise = true;
			},
			// 初始化数据
			async startData() {
				let _this = this;
				try {
					if (_this.$route.query.id > 0) {
						let results = await productInfo({
							id: _this.$route.query.id
						})
						if (results.success) {
                            let data = results.data;
                            _this.postageRemark = data.postageRemark;
                            _this.learnCategoryId = data.learnCategoryId;
							_this.ruleForm.advertisementWords = data.advertisementWords;
							_this.ruleForm.barCode = data.barCode;
							_this.ruleForm.costPrice = data.costPrice;
							_this.ruleForm.freightCalMethod = data.freightCalMethod;
							_this.ruleForm.freightPrice = data.freightPrice;
							_this.ruleForm.freightTemplateId = data.freightTemplateId;
							_this.ruleForm.goodsCode = data.goodsCode;
							_this.ruleForm.goodsImgUrls = data.goodsImgUrls ? data.goodsImgUrls : [];
							_this.ruleForm.goodsName = data.goodsName;
							_this.ruleForm.isEnableShoppingCard = data.isEnableShoppingCard;
							_this.ruleForm.isShowStock = data.isShowStock;
							_this.ruleForm.isShowSales = data.isShowSales;
							_this.ruleForm.mainVideoUrl = data.mainVideoUrl;
							_this.ruleForm.originalPrice = data.originalPrice;
							_this.ruleForm.salePrice = data.salePrice;
							_this.ruleForm.startSaleTimeType = data.startSaleTimeType;
							_this.ruleForm.supplierId = data.supplierId;
							_this.ruleForm.brandId = data.brandId;
							_this.ruleForm.timingSaleTime = data.timingSaleStartTime;
							_this.ruleForm.totalStock = data.totalStock;
							_this.ruleForm.unit = data.unit;
							_this.ruleForm.warningStock = data.warningStock;
							_this.ruleForm.goodsDesc = data.goodsDesc;
							_this.ruleForm.virtualSoldCount = data.virtualSoldCount;
							_this.ruleForm.saleLimitType = data.saleLimitType;
							_this.ruleForm.saleLimitQuantity = data.saleLimitQuantity;
							_this.ruleForm.timingOffTime = data.timingOffTime;
							_this.ruleForm.isOverseas = data.isOverseas;
							_this.ruleForm.isNeedCardImg = data.isNeedCardImg;
							_this.ruleForm.saleGoodsType = data.saleGoodsType; //商品类型 1普通商品 2直播特供商品 3：24H模式商品
							_this.ruleForm.recommendAwardFlag = data.recommendAwardFlag; //是否计算平推奖 0否 1是
							_this.ruleForm.familyAwardFlag = data.familyAwardFlag; //是否计算高管奖 0否 1是
							_this.ruleForm.boleAwardFlag = data.boleAwardFlag; //是否计算家族奖 0否 1是
							_this.ruleForm.manageProfitConfig = data.manageProfitConfig * 100; //商品在此订单详情的管理奖励配置
							_this.ruleForm.appreciatSericeId = data.appreciatSericeId; //增值服务id
							_this.ruleForm.appreciatSerice = data.appreciatSerice; //增值服务是否开启0否，1是
							_this.ruleForm.subsidyMoney = data.subsidyMoney;
							_this.ruleForm.growRate = data.growRate;    //活跃值
							_this.ruleForm.ledgerAccountType = data.ledgerAccountType;    //商品分账类型
							_this.ruleForm.isHide = data.isHide;
							_this.ruleForm.isShare = data.isShare ? true:false;
                            let ledgerAccountType = 'A';
                            if (data.ledgerAccountType == 2) {
                                ledgerAccountType = 'B'
                            }else if (data.ledgerAccountType == 3){
                                ledgerAccountType = 'C'
                            }
							//处理限时限购时间
							if (data.timeLimitStartTime && data.timeLimitEndTime) {
								_this.ruleForm.timeLimitTime = [data.timeLimitStartTime, data.timeLimitEndTime];
							}
							if (data.timingOffTime) {
								_this.ruleForm.timingOffTime = data.timingOffTime;
								_this.ruleForm.endSaleTimeType = true;
							}
							//处理推手收益
							if (data.pusherProfitConfigs) {
								data.pusherProfitConfigs.map(item => {
                                    _this.ruleForm['pusherProfitConfigs'+ledgerAccountType].map(item1 =>{
                                        if (item1.pusherLevel == item.pusherLevel) {
                                            item1.discountRate = item.discountRate;
                                        }
                                    })
								})
							}
                            //解冻库存金
                            _this.ruleForm.unfrozenStockBalanceMethod = data.unfrozenStockBalanceMethod;
                            if (data.unfrozenStockBalanceRateConfig) {
                                data.unfrozenStockBalanceRateConfig = JSON.parse(data.unfrozenStockBalanceRateConfig);
								let USBRConfig = this.ruleForm['USBRConfig'+ledgerAccountType];
                                USBRConfig[2].discountRate = data.unfrozenStockBalanceRateConfig.s;
                                USBRConfig[1].discountRate = data.unfrozenStockBalanceRateConfig.a;
                                USBRConfig[0].discountRate = data.unfrozenStockBalanceRateConfig.b;
							}
                            //解冻保证金
                            _this.ruleForm.unfrozenDepositMethod = data.unfrozenDepositMethod;
                            if (data.unfrozenDepositRateConfig) {
                                data.unfrozenDepositRateConfig = JSON.parse(data.unfrozenDepositRateConfig);
                                let UDRConfig = this.ruleForm['UDRConfig'+ledgerAccountType];
                                UDRConfig[1].discountRate = data.unfrozenDepositRateConfig.s;
                                UDRConfig[0].discountRate = data.unfrozenDepositRateConfig.a;
							}
							//处理分类数据
							this.ruleForm.categoryId = [data.category1Id,data.category2Id,data.categoryId];
							this.pageLoading = true;
							//处理规格名数据
							if (data.goodSpecsList.length) {
								this.goodsSpecValueList = data.goodSpecsList.map(item => {
									item.guid = _this.guid();
									let tempSpecValueName = []
									item.specValues.map((specValue, index) => {
										tempSpecValueName.push({
											specValueName: specValue,
											imgUrl: item.hasImg ? item.specImgUrls[index] : ''
										})
									})
									item.specValues = tempSpecValueName;
									return item;
								})
								this.brothSpece();
								//处理规格明细数据
								let startDataGoodsSpecValueList = JSON.parse(JSON.stringify(this.goodsSpecValueList));
								let startDataGoodsSpecList = JSON.parse(JSON.stringify(this.ruleForm.goodsSpecList));
								startDataGoodsSpecList.map(item => {
									item.specValues = [];
									startDataGoodsSpecValueList.map(startDataGoodsSpecValue => {
										item.specValues.push(item[startDataGoodsSpecValue.guid]);
									})
								})

								this.ruleForm.goodsSpecList = data.skuResponseList.map(item => {
									startDataGoodsSpecList.map(startDataGoodsSpec => {
										if (JSON.stringify(startDataGoodsSpec.specValues) == JSON.stringify(item.specValues)) {
											startDataGoodsSpec.originalPrice = item.originalPrice;
											startDataGoodsSpec.salePrice = item.salePrice;
											startDataGoodsSpec.skuCode = item.skuCode;
											startDataGoodsSpec.stock = item.stock;
											startDataGoodsSpec.growRate = item.growRate;
											startDataGoodsSpec.barCode = item.barCode;
											startDataGoodsSpec.skuId = item.skuId;
											item = startDataGoodsSpec;
											return false;
										}
									})
									return item;
								})
								this.ruleForm.copyGoodsSpecList = JSON.parse(JSON.stringify(this.ruleForm.goodsSpecList));
								this.ruleForm.showGoodsSpecList = JSON.parse(JSON.stringify(this.ruleForm.goodsSpecList)).splice(0, this.pageSize);
								this.total = this.ruleForm.goodsSpecList.length;
								this.pageNo = 1;
							}
							try {
								//处理售后服务数据
								this.ruleForm.afterSaleServiceSettings1 = data.afterSaleServiceSettings.indexOf(1) != -1;
								this.ruleForm.afterSaleServiceSettings2 = data.afterSaleServiceSettings.indexOf(2) != -1;
								this.ruleForm.afterSaleServiceSettings3 = data.afterSaleServiceSettings.indexOf(3) != -1;

								//处理商品标签
								let goodsTags = data.tagIds;
                                console.log('goodsTags',goodsTags);
                                if (goodsTags) {
                                    goodsTags.map(item => {
                                        this.ruleForm.tags.map(item1 =>{
                                            if (item == item1.id) {
                                                item1.isSelect = true;
                                            }
                                        })
                                    })
                                }
							} catch (error) {
								console.log(error);
                            }
                            //处理服务承诺的数据
                            if (data.promiseSerice) {
                                this.promiseList.map((item,index) => {
                                    data.promiseSerice.map(promise =>{
                                        if (item.id == promise) {
                                            item['promise'+index] = true;
                                        }
                                    })
                                })
                            }
						}

					}
				} catch (e) {
					console.log(e)
				} finally {
					this.$nextTick(function() {
						if (this.$refs.myTextEditor.quill) {
							this.$refs.myTextEditor.quill.enable(true);
							this.$refs.myTextEditor.quill.blur();
							window.scrollTo(0, 0);
						}
					});
					this.pageLoading = false
				}
			},
			//筛选数据
			async getInfor() {
				try {
					//获取供应商
					let result = await supplierGetList({
						pageSize: 10000,
						pageNo: 0
					})
                    this.productList = result.data.records;
                    //获取服务承诺
                    let result1 = await getPromiseList({
						pageSize: 10000,
						pageNo: 0
                    })
                    result1 = result1.data.records;
					console.log('--------',result1);
                    result1.map((item,index) =>{
						if(item.promiseSericeName=='假一赔十' || item.promiseSericeName=='正品保障'){
							item['promise'+index] = true;
						}else{
							item['promise'+index] = false;
						}
                    })
                    this.promiseList = result1;
                    //获取增值服务
                    let result2 = await getAppreciatList({
						pageSize: 10000,
						pageNo: 0
					})
                    this.appreciatList = result2.data.records;

					// 获取运费模板数据
					let result3 = await freightTemplateList({
						pageSize: 10000,
						pageNo: 0
					})
					this.FreightTemplateIdList = result3.data.records;
					//获取类目
					let result4 = await categoryList()
					this.formatCategoryList(result4.data);
					this.CategorySelectItemList = result4.data;
                    //获取品牌商
					let result5 = await findBrandPage({
						pageSize: 10000,
						pageNo: 0
					})
                    this.brandList = result5.data.records;
                    this.showBrandList = JSON.parse(JSON.stringify(this.brandList));
                    //获取奖励配置
                    let result6 = await getAwardConfigSetting();
                    // 推手收益
                    let item = JSON.parse(result6.data.discountConfig);
                    this.formatProfitConfigs('pusherProfitConfigsA',item[0]);
                    this.formatProfitConfigs('pusherProfitConfigsB',item[1]);
                    this.formatProfitConfigs('pusherProfitConfigsC',item[2]);
                    // 解冻库存金比例
                    let item1 = JSON.parse(result6.data.unfrozenStockBalanceRateConfig);
                    this.formatUSBRConfigs('USBRConfigA',item1[0]);
                    this.formatUSBRConfigs('USBRConfigB',item1[1]);
                    this.formatUSBRConfigs('USBRConfigC',item1[2]);
                    // 解冻保证金比例
                    let item2 = JSON.parse(result6.data.unfrozenDepositRateConfig);
                    this.formatUDRConfigs('UDRConfigA',item2[0]);
                    this.formatUDRConfigs('UDRConfigB',item2[1]);
                    this.formatUDRConfigs('UDRConfigC',item2[2]);
                    //伯乐奖
                    let item3 = JSON.parse(result6.data.boleAwardConfig);
                    this.boleAwardConfigA = item3[0];
                    this.boleAwardConfigB = item3[1];
                    this.boleAwardConfigC = item3[2];
                    //平推奖
                    let item4 = JSON.parse(result6.data.recommendAwardConfig);
					this.recommendAwardConfigA = item4[0];
					this.recommendAwardConfigB = item4[1];
					this.recommendAwardConfigC = item4[2];

                    //获取商品标签
                    let result7 = await listTags({
                        pageNo: 1,
                        pageSize: 10000,
                    });
                    result7.data.records.map(item => {
                        item.isSelect = false;
                    })
                    this.ruleForm.tags = result7.data.records;

				} catch (e) {
					console.log(e)
				} finally {
					this.startData();
				}
			},
            //格式化奖励
            formatProfitConfigs(a,b){

                this.ruleForm[a] = JSON.parse(JSON.stringify(this.ruleForm.pusherProfitConfigs));
                this.ruleForm[a][0].discountRate = b.z;
                this.ruleForm[a][1].discountRate = b.c;
                this.ruleForm[a][2].discountRate = b.b;
                this.ruleForm[a][3].discountRate = b.a;
                this.ruleForm[a][4].discountRate = b.s;
            },
            //格式化解冻库存金比例
            formatUSBRConfigs(a,b){
                this.ruleForm[a] = [{
                    pusherLabel:this.ruleForm.pusherProfitConfigs[2].pusherLabel,
                    discountRate:null
                },{
                    pusherLabel:this.ruleForm.pusherProfitConfigs[3].pusherLabel,
                    discountRate:null
                },{
                    pusherLabel:this.ruleForm.pusherProfitConfigs[4].pusherLabel,
                    discountRate:null
                }];
                this.ruleForm[a][0].discountRate = b.b;
                this.ruleForm[a][1].discountRate = b.a;
                this.ruleForm[a][2].discountRate = b.s;
            },
            //格式保证金比例
            formatUDRConfigs(a,b){
                this.ruleForm[a] = [{
                    pusherLabel:this.ruleForm.pusherProfitConfigs[3].pusherLabel,
                    discountRate:null
                },{
                    pusherLabel:this.ruleForm.pusherProfitConfigs[4].pusherLabel,
                    discountRate:null
                }];
                this.ruleForm[a][0].discountRate = b.a;
                this.ruleForm[a][1].discountRate = b.s;
            },
			//格式化类目数据
			formatCategoryList(array) {
				for (let i = 0; i < array.length; i++) {
					if (array[i].categoryLevel == 3) {
						delete array[i].childCategoryList;
					} else {
						this.formatCategoryList(array[i].childCategoryList);
					}
				}
			},
			// 点击上传规格图片
			showImages(indexs, index) {
				this.dataId = {
					indexs: indexs,
					index: index
				};
			},
			// 上传规格图成功钩子
			handleAvatarSuccess1(res) {
				console.log(res);
				this.goodsSpecValueList[this.dataId.indexs].specValues[this.dataId.index].imgUrl = res.data[0];
				return true;
			},
			// 上传规格图前钩子
			beforeAvatarUpload1(file) {
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
				const isLt3M = file.size / 1024 / 1024 < 3;
				if (!isJPG) {
					this.$message.error('上传头像图片必须是 JPG 或者PNG格式!');
				}
				if (!isLt3M) {
					this.$message.error('图片大小请控制在3M以内!');
				}
				return isJPG && isLt3M;
			},

			// 获取规格库数据
			async getSpeceData() {
				try {
					this.speceTitleData = []
					let result = await getSpecList({
						pageNo: 0,
						pageSize: 10000,
						specName: ""
					});
					if (result.success) {
						result.data.records.map(item => {
							item.disabled = false;
						})
						this.speceTitleData = result.data.records;
					}
				} catch (e) {
					console.log(e)
				}
			},
			handleInput2(e) {
				// 通过正则过滤小数点后两位
				e.target.value = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null

			},
			// 批量上传的图片
			transPictureFun(datas) {
				this.ruleForm.goodsImgUrls = datas;
			},
			//上传视频成功钩子
			handleVideoSuccess(res) {
				this.ruleForm.mainVideoUrl = res.data[0];
				this.loading = false;
			},
			// 上传视频前的钩子
			beforeUploadVideo(file) {
				const isLt30M = file.size / 1024 / 1024 < 30;
				if (['video/mp4'].indexOf(file.type) == -1) {
					this.$message.error('请上传正确的视频格式');
					return false;
				}
				if (!isLt30M) {
					this.$message.error('主图视频请控制在30M以内');
					return false;
				}
				this.loading = true;
			},
			//上传视频成功回调
			uploadVideoProcess(event, file, fileList) {
				this.videoFlag = true;
				this.videoUploadPercent = file.percentage.toFixed(0);
				console.log(fileList);
			},
			//展开规格名选择时，回调函数
			inputFunction1() {
				let _this = this;
				//只能选没有的选项
				_this.speceTitleData.map(speceTitle => {
					speceTitle.disabled = false;
					_this.goodsSpecValueList.map(goodsSpec => {
						if (goodsSpec.specName == speceTitle.specName) {
							speceTitle.disabled = true;
						}
					})
				})
			},
			//展开规格值选择时，显示可选值
			inputFunction(indexs) {
				// this.brothSpece();
				var _this = this;
				let temp = _this.goodsSpecValueList[indexs].specName;
				let specValues = _this.goodsSpecValueList[indexs].specValues;
				let specevalData = [];
				if (temp) {
					_this.speceTitleData.map(item => {
						if (temp == item.specName) {
							item.specValues.map(itemSpecValue => {
								let temp = {
									name: itemSpecValue,
									disabled: false
								}
								specValues.map(specValue => {
									if (specValue.specValueName == itemSpecValue) {
										temp.disabled = true;
									}
								})
								specevalData.push(temp);
							})
							_this.specevalData = specevalData;
						}
					})
				}
			},
			// 生成规格明细
			brothSpece() {
				//组织数据
				this.formatSku(this.goodsSpecValueList[0], [], 0);
			},
			//处理规格数据
			formatSku(goodsSpec, req, num) {
				let _this = this;
				let temp = [];
				goodsSpec.specValues.map((item, index) => {
					if (req.length == 0) {
						temp.push({
							[goodsSpec.guid]: item.specValueName,
							skuCode: _this.formatSkuCode('', index + 1, num)
						});
					} else {
						req.map(itemNext => {
							temp.push({
								...itemNext,
								[goodsSpec.guid]: item.specValueName,
								skuCode: _this.formatSkuCode(itemNext.skuCode, index + 1, num)
							});
						})
					}
				})
				if (goodsSpec.guid == this.goodsSpecValueList[this.goodsSpecValueList.length - 1].guid) {
					//已经是最后一级了
					for (let i = 0; i < temp.length; i++) {

						//处理规格明细数据
						let saveGoodsSpecValueList = JSON.parse(JSON.stringify(this.goodsSpecValueList));
						temp[i].specValues = [];
						saveGoodsSpecValueList.map(saveGoodsSpecValue => {
							temp[i].specValues.push(temp[i][saveGoodsSpecValue.guid]);
						})
						//判断是否需要填充数据
						let oldTemp = '';
						if (this.ruleForm.copyGoodsSpecList.length) {
							for (let n = 0; n < this.ruleForm.copyGoodsSpecList.length; n++) {
								if (JSON.stringify(temp[i].specValues) == JSON.stringify(this.ruleForm.copyGoodsSpecList[n].specValues)) {
									oldTemp = this.ruleForm.copyGoodsSpecList[n];
								}
							}
						}
						temp[i].uid = this.guid();
						temp[i].salePrice = oldTemp.salePrice ? oldTemp.salePrice : null; //售价
						temp[i].originalPrice = oldTemp.originalPrice ? oldTemp.originalPrice : null; //划线价
						// temp[i].skuCode = null;       //商品编码
						temp[i].stock = oldTemp.stock ? oldTemp.stock : 0; //库存
						temp[i].growRate = oldTemp.growRate ? oldTemp.growRate : 0; //活跃值
						temp[i].barCode = oldTemp.barCode ? oldTemp.barCode : ''; //重量
						temp[i].virtualSoldCount = oldTemp.virtualSoldCount ? oldTemp.virtualSoldCount : 0; //虚拟销量
						temp[i].skuId = oldTemp.skuId ? oldTemp.skuId : null;
					}
					this.ruleForm.goodsSpecList = temp;
					this.ruleForm.copyGoodsSpecList = temp;
					this.ruleForm.showGoodsSpecList = JSON.parse(JSON.stringify(temp)).splice(0, this.pageSize);
					this.total = temp.length;
					this.pageNo = 1;

					this.$forceUpdate();
				} else {
					//还不是最后一级
					this.formatSku(this.goodsSpecValueList[num + 1], temp, num + 1);
				}
			},
			//补全0
			formatSkuCode(code, index, num) {
				if (index < 10) {
					index = '0' + index;
				}

				code += (num + 1) + index;
				return code;
			},
			//删除规格名
			closeFunction(indexs) {
				this.goodsSpecValueList.splice(indexs, 1);
				if (this.goodsSpecValueList.length == 0) {
					this.ruleForm.goodsSpecList = [];
				} else {
					this.brothSpece();
				}

			},
			// 添加规格
			addSprces() {
				//组织数据
				let temp = {
					guid: this.guid(), //id
					hasImg: false, //是否带图
					specName: '', //规格名称
					specImgUrls: [], //规格图列表，对应规格值
					specValues: [{ //规格值名称集合
						specValueName: '',
						imgUrl: ''
					}],
					closeShow: true, //是否显示关闭图标
				}
				if (this.goodsSpecValueList.length == 0) {
					temp.hasImg = true;
				}
				this.goodsSpecValueList.push(temp);
				this.$forceUpdate();
			},
			//添加规格值
			addSpece(obj) {
				if(this.isEdit==0){
					return;
				}
				obj.specValues.push({
					specValueName: '',
					imgUrl: ''
				});
				this.$forceUpdate();
				setTimeout(() => {
					this.brothSpece();
				}, 200)
			},
			//修改带图的规格
			changeHasImg(item, index) {
				console.log(item, index);
				for (let i = 0; i < this.goodsSpecValueList.length; i++) {
					this.goodsSpecValueList[i].hasImg = false;
				}
				this.goodsSpecValueList[index].hasImg = true;
			},
			//生成唯一ID
			guid() {
				var a = function() {
					return (65536 * (1 + Math.random()) | 0).toString(16).substring(1)
				};
				return 'a' + a() + a() + "-" + a() + "-" + a() + "-" + a() + "-" + a() + a() + a()
			},
			//删除规格值
			closeFuns(arr, index) {
				if (arr.length == 1) {
					this.$message.error('规格值至少保留一个!');
					return;
				}
				arr.splice(index, 1);
				this.$forceUpdate();
				setTimeout(() => {
					this.brothSpece();
				}, 200)
			},

			isRepeat(arr) {
				var hash = {};
				for (var i in arr) {
					if (hash[arr[i]]) {
						return true;
					}
					hash[arr[i]] = true;
				}
				return false;
			},

			// 保存商品信息
			submitForm() {
				let _this = this;
				//图片最多10张
				if (this.ruleForm.goodsImgUrls.length > 10) {
					this.ruleForm.goodsImgUrls.splice(10)
                }
                //如果开启了增值服务，那么一定要选择服务id
                if (this.ruleForm.appreciatSerice && !this.ruleForm.appreciatSericeId) {
					this.$message({
                        showClose: true,
                        type: 'error',
                        message: '请选择增值服务！'
                    });
                    return;
                }
				//如果开启了增值服务，运费补贴必填
				if (this.ruleForm.appreciatSerice && !this.ruleForm.subsidyMoney) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '请填写运费补贴金额'
					});
					return;
				}
                //如果使用运费模版，运费模版不能为空
                if (this.ruleForm.freightCalMethod == 2) {
                    if (!this.ruleForm.freightTemplateId) {
                        this.$message({
                            showClose: true,
                            type: 'error',
                            message: '请选择运费模版！'
                        });
                        return;
                    }
                }
				//规格明细商品条码不能重复
				var list = [];
				this.ruleForm.goodsSpecList.map(item => {
					if (item.barCode) {
						list.push(item.barCode);
					}
					return item;
				})
				if (this.isRepeat(list)) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '规格明细商品条码不能重复'
					});
					return;
				}
				this.$refs['ruleForm'].validate(async (valid) => {
					if (valid) {
						//处理规格数据
						if (this.goodsSpecValueList.length > 0) {
							let op = false;
							let op1 = false;
							this.ruleForm.goodsSpecList.map(item => {
								item.virtualSoldCount = 0;
								for (let i in item) {
									if (!item[i] && item[i] != 0 && i != 'skuId' && i != 'barCode') {
										op = true;
										break;
									}
								}
								if (op) {
									return false;
								}
								if (Number(item.originalPrice) < Number(item.salePrice)) {
									op1 = true;
									return false;
								}
							})
							if (op) {
								this.$message({
									showClose: true,
									type: 'error',
									message: '请完善规格信息!'
								});
								return;
							}
							if (op1) {
								this.$message({
									showClose: true,
									type: 'error',
									message: '划线价不能小于销售价!'
								});
								return;
							}
							if (this.ruleForm.goodsSpecList.length) {
								this.ruleForm.goodsSpecList[0].virtualSoldCount = this.ruleForm.virtualSoldCount;
							}
						} else {
							//处理划线价的问题
							if (Number(this.ruleForm.originalPrice) < Number(this.ruleForm.salePrice)) {
								this.$message({
									showClose: true,
									type: 'error',
									message: '划线价不能小于销售价'
								});
								return;
							}
						}
						this.loading = true
						try {
                            let tags = [];
                            this.ruleForm.tags.map(item => {
                                if (item.isSelect) {
                                    tags.push(item.id);
                                }
                            })
							//组织需要提交的数据
                            let category1Id = this.ruleForm.categoryId[0];
                            let category2Id = this.ruleForm.categoryId[1];
                            let categoryId = this.ruleForm.categoryId[2];

							let data = {
								"advertisementWords": this.ruleForm.advertisementWords,
								"afterSaleServiceSettings": "",
								"barCode": this.ruleForm.barCode,
								"category1Id": category1Id,
								"category2Id": category2Id,
								"categoryId": categoryId,
								"costPrice": this.ruleForm.costPrice,
								"freightCalMethod": this.ruleForm.freightCalMethod,
								"freightPrice": this.ruleForm.freightPrice,
								"freightTemplateId": this.ruleForm.freightTemplateId,
								"goodSpecsList": [],
								"goodsCode": this.ruleForm.goodsCode,
								"goodsId": '',
								"goodsImgUrls": this.ruleForm.goodsImgUrls,
								"goodsName": this.ruleForm.goodsName,
								"goodsSpecType": 1,
								"isEnableShoppingCard": this.ruleForm.isEnableShoppingCard,
								"isShowStock": this.ruleForm.isShowStock,
								"isShowSales": this.ruleForm.isShowSales,
								"mainVideoUrl": this.ruleForm.mainVideoUrl,
								"originalPrice": this.ruleForm.originalPrice,
								"pusherProfitConfigs": '',
                                "unfrozenDepositMethod": this.ruleForm.unfrozenDepositMethod,
                                "unfrozenDepositRateConfig": "",
                                "unfrozenStockBalanceMethod": this.ruleForm.unfrozenStockBalanceMethod,
                                "unfrozenStockBalanceRateConfig": "",

								"saleLimitType": this.ruleForm.saleLimitType,
								"salePrice": this.ruleForm.salePrice,
								"skuAddRequestList": [],
								"startSaleTimeType": this.ruleForm.startSaleTimeType,
								"stockReduceMethod": this.ruleForm.stockReduceMethod,
								"supplierId": this.ruleForm.supplierId,
								"brandId": this.ruleForm.brandId,
								"timeLimitEndTime": "",
								"timeLimitStartTime": "",
								"totalStock": this.ruleForm.totalStock,
								"unit": this.ruleForm.unit,
								"warningStock": this.ruleForm.warningStock,
								"goodsDesc": this.ruleForm.goodsDesc,
								"virtualSoldCount": this.ruleForm.virtualSoldCount,
								"saleLimitQuantity": this.ruleForm.saleLimitQuantity,
								"isOverseas": this.ruleForm.isOverseas,
								"isNeedCardImg": this.ruleForm.isNeedCardImg,
								"goodsTags": JSON.stringify(tags),
                                "tagsId":tags,
								"saleGoodsType": this.ruleForm.saleGoodsType, //商品类型 1普通商品 2直播特供商品 3：24H模式商品
								"recommendAwardFlag": this.ruleForm.recommendAwardFlag, //是否计算平推奖 0否 1是
								"familyAwardFlag": this.ruleForm.familyAwardFlag, //是否计算高管奖 0否 1是
								"boleAwardFlag": this.ruleForm.boleAwardFlag, //是否计算伯乐 0否 1是
								"manageProfitConfig": this.ruleForm.manageProfitConfig / 100, //商品在此订单详情的管理奖励配置
								"appreciatSericeId": this.ruleForm.appreciatSericeId, //增值服务id
								"appreciatSerice": this.ruleForm.appreciatSerice, //增值服务开关
								"subsidyMoney":this.ruleForm.subsidyMoney,
								"growRate":this.ruleForm.growRate,
								"ledgerAccountType":this.ruleForm.ledgerAccountType,
								"isHide":this.ruleForm.isHide,
								"isShare":this.ruleForm.isShare ? '1' : '0',
							}
							data.timingOffTime = '';
							if (this.ruleForm.endSaleTimeType) {
								data.timingOffTime = this.ruleForm.timingOffTime;
							}
							data.timingSaleStartTime = '';
							if (this.ruleForm.startSaleTimeType == 2) {
								data.timingSaleStartTime = this.ruleForm.timingSaleTime;
							}

							//处理售后服务
							if (this.ruleForm.afterSaleServiceSettings1) {
								data.afterSaleServiceSettings = 1;
							}
							if (this.ruleForm.afterSaleServiceSettings2) {
								data.afterSaleServiceSettings = data.afterSaleServiceSettings ? data.afterSaleServiceSettings + ',2' : '2'
							}
							if (this.ruleForm.afterSaleServiceSettings3) {
								data.afterSaleServiceSettings = data.afterSaleServiceSettings ? data.afterSaleServiceSettings + ',3' : '3'
							}
                            //处理推手收益相关
                            let ledgerAccountType = 'A';
                            if (this.ruleForm.ledgerAccountType == 2) {
                                ledgerAccountType = 'B';
                            }else if (this.ruleForm.ledgerAccountType == 3) {
                                ledgerAccountType = 'C';
                            }
                            //处理销售折扣比率
                            let pusherProfitConfigs = JSON.parse(JSON.stringify(this.ruleForm['pusherProfitConfigs'+ledgerAccountType]));
                            //处理推手收益数据，前者不能小于后者
                            for (let i = 0; i < pusherProfitConfigs.length - 1; i++) {
                                if (Number(pusherProfitConfigs[i].discountRate) < Number(pusherProfitConfigs[i + 1].discountRate)) {
                                    this.$message({
                                        showClose: true,
                                        type: 'error',
                                        message: pusherProfitConfigs[i + 1].pusherLabel + '自营收益折扣不能高于' + pusherProfitConfigs[i].pusherLabel + '自营收益折扣！'
                                    });
                                    return;
                                }
                            }
                            pusherProfitConfigs.map(item =>{
                                item.discountRate = item.discountRate;
                            })
                            data.pusherProfitConfigs = pusherProfitConfigs;
                            //处理保证金
                            if (this.ruleForm.unfrozenDepositMethod == 1) {
                                let UDRConfig = this.ruleForm['UDRConfig'+ledgerAccountType];
                                //处理保证金，前者不能小于后者
                                for (let i = 0; i < UDRConfig.length - 1; i++) {
                                    if (Number(UDRConfig[i].discountRate) < Number(UDRConfig[i + 1].discountRate)) {
                                        this.$message({
                                            showClose: true,
                                            type: 'error',
                                            message: UDRConfig[i + 1].pusherLabel + '保证金折扣不能高于' + UDRConfig[i].pusherLabel + '折扣！'
                                        });
                                        return;
                                    }
                                }
                                data.unfrozenDepositRateConfig= {
                                    s:UDRConfig[1].discountRate,
                                    a:UDRConfig[0].discountRate};
                                data.unfrozenDepositRateConfig = JSON.stringify(data.unfrozenDepositRateConfig);
                            }
                            //处理库存金
                            if (this.ruleForm.unfrozenStockBalanceMethod == 2) {
                                let USBRConfig = this.ruleForm['USBRConfig'+ledgerAccountType];
                                //处理库存金，前者不能小于后者
                                for (let i = 0; i < USBRConfig.length - 1; i++) {
                                    if (Number(USBRConfig[i].discountRate) < Number(USBRConfig[i + 1].discountRate)) {
                                        this.$message({
                                            showClose: true,
                                            type: 'error',
                                            message: USBRConfig[i + 1].pusherLabel + '库存金折扣不能高于' + USBRConfig[i].pusherLabel + '折扣！'
                                        });
                                        return;
                                    }
                                }
                                data.unfrozenStockBalanceRateConfig= {
                                    s:USBRConfig[2].discountRate,
                                    a:USBRConfig[1].discountRate,
                                    b:USBRConfig[0].discountRate};
                                data.unfrozenStockBalanceRateConfig = JSON.stringify(data.unfrozenStockBalanceRateConfig);
                            }
                            // console.log('UDRConfig',this.ruleForm['UDRConfig'+ledgerAccountType]);
                            // console.log('USBRConfig',this.ruleForm['USBRConfig'+ledgerAccountType]);
							//处理规格数据
							if (this.goodsSpecValueList.length > 0) {
								data.goodsSpecType = 2;
								let saveGoodsSpecValueList = JSON.parse(JSON.stringify(this.goodsSpecValueList));

								saveGoodsSpecValueList.map(item => {
									let specValues = [];
									let specImgUrls = [];
									item.specValues.map(specValue => {
										if (item.hasImg) {
											if (specValue.imgUrl) {
												specImgUrls.push(specValue.imgUrl);
											} else {
												specImgUrls.push(_this.ruleForm.goodsImgUrls[0]);
											}

										}
										specValues.push(specValue.specValueName);
									})
									item.specValues = specValues;
									item.specImgUrls = specImgUrls;
								})
								data.goodSpecsList = saveGoodsSpecValueList;

								//处理规格明细数据
								let saveGoodsSpecList = JSON.parse(JSON.stringify(this.ruleForm.goodsSpecList));
								saveGoodsSpecList.map(item => {
									item.specValues = [];
									saveGoodsSpecValueList.map(saveGoodsSpecValue => {
										item.specValues.push(item[saveGoodsSpecValue.guid]);
									})
								})
								data.skuAddRequestList = saveGoodsSpecList;
							}
							//限时限购时间处理
							if (this.ruleForm.timeLimitTime) {
								data.timeLimitStartTime = this.ruleForm.timeLimitTime[0];
								data.timeLimitEndTime = this.ruleForm.timeLimitTime[1];
                            }
                            //处理服务承诺的数据
                            data.promiseSerice = [];
                            this.promiseList.map((item,index) => {
                                if (item['promise'+index]) {
                                    data.promiseSerice.push(item.id);
                                }

                            })
                            console.log('data',data);
                            // return;
							let result = '';
							if (this.$route.query.id > 0) {
								data.goodsId = this.$route.query.id;
								result = await productEdit(data);
							} else {
								result = await productSave(data);
							}



							if (result.success) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '保存成功'
								});

								this.$store.state.tagsView.visitedViews = this.$store.state.tagsView.visitedViews.filter(
									(item) => {
										return item.path != this.$route.path;
									}
                                );
                                if (this.learnCategoryId) {
                                    this.$router.push({
                                        path: '/goods/cps/vmsGoods'
                                    });
                                }else{
                                    this.$router.push({
                                        path: '/goods/goodsList'
                                    });
                                }

							} else {
								this.$message({
									showClose: true,
									type: 'error',
									message: result.alertMsg
								});
							}
						} catch (e) {
							console.log(e)
							this.loading = false
						} finally {
							setTimeout(() => {
								this.loading = false
							}, 600)
						}
					} else {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请填写完整的商品信息'
						});
						return false;
					}
				});
			},
			// 取消操作
			canselGoods() {
				this.$confirm('您还未保存编辑的数据，离开页面后，系统将不会为您保存数据，是否确认继续离开?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$store.state.tagsView.visitedViews = this.$store.state.tagsView.visitedViews.filter(
						(item) => {
							return item.path != this.$route.path;
						}
					);
					if (this.learnCategoryId) {
                        this.$router.push({
                            path: '/goods/cps/vmsGoods'
                        });
                    }else{
                        this.$router.push({
                            path: '/goods/goodsList'
                        });
                    }
				}).catch(() => {
					this.$message({
						showClose: true,
						type: 'info',
						message: '已取消操作'
					});
				})
			},
			radioChange(val) {
				this.ruleForm.afterSaleServiceSettings1 = !val; //是否允许退款
				this.ruleForm.afterSaleServiceSettings2 = !val; //是否允许退款退货
				this.ruleForm.afterSaleServiceSettings3 = false; //允许换货
			},
			//商品类型标签
			goodsTypeRadioChange(val) {
				this.liveGoodsConfig.filter(item => {
					if(val === 2) {
						item.val1 = this.ruleForm[item.name];
					}
					this.ruleForm[item.name] = item['val' + val];
				});
				if(val === 4) {
					this.ruleForm.isEnableShoppingCard = false;
					this.ruleForm.afterSaleServiceSettings1 = false;
					this.ruleForm.afterSaleServiceSettings2 = false;
					this.ruleForm.unfrozenStockBalanceMethod = 0;
					this.ruleForm.unfrozenDepositMethod = 0;
				}
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.pageSize = val;
				this.ruleForm.showGoodsSpecList = JSON.parse(JSON.stringify(this.ruleForm.goodsSpecList)).splice(this.pageSize * (
					this.pageNo - 1), this.pageSize);
				console.log(this.ruleForm.showGoodsSpecList);
				this.$forceUpdate();
			},
			// 翻页
			handleCurrentChange(val) {
				this.pageNo = val;
				this.ruleForm.showGoodsSpecList = JSON.parse(JSON.stringify(this.ruleForm.goodsSpecList)).splice(this.pageSize * (
					this.pageNo - 1), this.pageSize);
				console.log(this.ruleForm.showGoodsSpecList);
				this.$forceUpdate();
			},
			//监听输入变化
			inputChange(spec, name) {
				console.log(spec);
                //计算活跃值
				this.ruleForm.goodsSpecList.map(item => {
					if (item.uid == spec.uid) {
						item[name] = spec[name];
                        if (name == 'salePrice') {
                            let salePrice = Number(spec.salePrice);
                            if (salePrice) {
                                salePrice = spec.salePrice / 100;
                                spec.growRate = this.formatGrowRate(salePrice);
                            }else{
                                spec.growRate = null;
                            }
                            item.growRate = spec.growRate;
                        }
					}
				})
                if(name == 'salePrice' && spec[name]){
                    let salePrice = Number(spec[name]);
                    salePrice = spec.salePrice / 100;
                    spec.growRate = this.formatGrowRate(salePrice);
                }else if(!spec[name]){
                    spec.growRate = '';
                }
			},
            //小数格式化
            formatGrowRate(num){
                return Math.round(num * 100)/100;
            },
            //供应商切换
            changeSupplierId(){
                let _this = this;
                let brandList = JSON.parse(JSON.stringify(_this.brandList));
                let op = true;
                if (_this.ruleForm.supplierId) {
                    let temp = [];
                    brandList.map(item =>{
                        if (item.supplierId == _this.ruleForm.supplierId) {
                            temp.push(item);
                            if (item.id == _this.ruleForm.brandId) {
                                op = false;
                            }
                        }

                    })
                    _this.showBrandList = temp;
                }else{
                    _this.showBrandList = brandList;
                }
                if (op) {
                    _this.ruleForm.brandId = '';
                }
            },
            //品牌切换
            changeBrandId(){
                this.showBrandList.map(item =>{
                    if (item.id == this.ruleForm.brandId) {
                        this.ruleForm.supplierId = item.supplierId;
                        this.changeSupplierId();
                    }
                })
            },
            //商品类型替换
            changeLAType(e){
                console.log('e',e);
            }
		},

	}
</script>

<style lang="less">
	.creatGoodDetails {
		.videoBox {
			position: relative;

			.deletIcon {
				width: 152px;
				height: 152px;
				position: absolute;
				top: 0;
				left: 0;
				background: rgba(0, 0, 0, 0.4);
				cursor: pointer;

				.deleteImg {
					position: absolute;
					font-size: 20px;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}

			.Displayblock {
				display: block;
			}

			.Displaynone {
				display: none;
			}
		}

		.priceNum {
			color: blue;
			cursor: pointer;
			margin-right: 20px;
		}

		.baseInformtion {
			width: 100%;
			background: #fff;
			padding: 10px;
			margin-bottom: 12px;

			.infortion {
				margin-bottom: 20px;
				padding: 10px 0;
				border-bottom: 1px solid #ccc;
			}
		}

		.textColor {
			color: #909399;
			font-size: 12px;
		}

		.spec-bos {
			background: #f0f2f5;
			padding: 5px 0;
		}

		.speceBos:last-child {
			margin-bottom: 30px;
		}

		.addSpece {
			color: blue;
			font-size: 15px;
			margin-left: 30px;
			cursor: pointer;
		}

		.close-bosx {
			position: absolute;
			right: -5px;
			top: -8px;
			font-size: 18px;
		}

		.close-bosx1 {
			position: absolute;
			right: 10px;
			top: 50%;
			font-size: 18px;
			transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
		}

		.close-bosx2 {
			position: absolute;
			right: -5px;
			top: -3px;
			font-size: 18px;
		}

		input[type="number"]::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}

		.number__input {
			border: 1px solid #ccc;
		}

		.quill-editor:not(.bubble) .ql-container,
		.quill-editor:not(.bubble) .ql-container .ql-editor {
			min-height: 300px;
			padding-bottom: 20px;
		}

		.bottom-save-btn {
			position: fixed;
			width: 100%;
			background: #fff;
			bottom: 0;
			text-align: center;
			padding: 20px 0;
			z-index: 100;
			box-shadow: 13px 1px 6px #999;
		}

		.el-upload {
			border: 1px dashed #d9d9d9;
			border-radius: 6px;
			cursor: pointer;
			position: relative;
			overflow: hidden;
		}

		.el-upload:hover {
			border-color: #409eff;
		}

		.avatar-uploader-icon {
			font-size: 28px;
			color: #8c939d;
			width: 152px;
			height: 152px;
			line-height: 152px;
			text-align: center;
		}

		.specparentbox {
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 100px;
				height: 100px;
				line-height: 100px;
				text-align: center;
			}

			.filter-container {
				padding-bottom: 0;
			}

			/deep/tbody {
				background: #fff;
			}

			/deep/.el-table__fixed-header-wrapper {
				background: #f8f8f9;
			}
		}

		.speceBoxe {
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 100px;
				height: 100px;
				line-height: 100px;
				text-align: center;
			}

			.filter-container .filter-item {
				vertical-align: top;
			}
		}

		.specparentbox {
			.filter-container .filter-item {
				margin: 0;
			}
		}

		.avatar {
			width: 150px;
			height: 150px;
			display: block;
		}

		.tablered {
			color: red;
			vertical-align: -3px;
			margin-right: 5px;
		}
	}
</style>
