<template>
	<div class="contanierBox">
		<div class="filter-container title-border">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-input v-model="searchKey" placeholder="商品名称、编码、规格条形码" clearable style="width:300px"></el-input>
			</div>			

			<!-- 销售分组 -->
			<div class="filter-item">
				<label class="label">商品分组: </label>
				<el-select v-model="group" placeholder="请选择" clearable>
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="(item,index) in groupOptions" :key="index" :label="item.GroupName" :value="item.Id">
					</el-option>
				</el-select>
			</div>
			<!-- 商品品牌 -->
			<div class="filter-item">
				<label class="label">商品品牌: </label>
				<el-select v-model="brand" placeholder="请选择" clearable>
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="(item,index) in brandOptions" :key="index" :label="item.BrandName" :value="item.Id">
					</el-option>
				</el-select>
			</div>
			<!-- button -->
			<div class="filter-item">
				<el-button type="primary" style="margin:0 30px;width:90px;" @click="handleFilter">查询</el-button>
			</div>
		</div>
		<!-- table -->
		<div class="table-container content" style="max-height:500px;overflow: auto;">
			<!-- :row-key="getRowKeys" -->
			<!-- @select='onTableSelect'  -->
			<el-table :data="tableDataList" style="width: 100%" :loading='loading' ref="compSelectProTable" @select-all='selectAll' :row-key="getRowKeys"
			 @selection-change="handleSelectionChange" empty-text="筛选结果为空">
				<el-table-column type="selection" width="55" reserve-selection>
				</el-table-column>
				<el-table-column prop="Name" label="商品" width='300'>
					<template slot-scope="scope">
						<div style="width:300px;">
							<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="SpecValue" label="规格">
					<template slot-scope="scope">
						<span v-if="scope.row.SpecValue">
							<span>{{scope.row.SpecValue}}</span>
						</span>
						<span v-else>默认规格</span>
						<span v-if="scope.row.SpecValue2">，{{scope.row.SpecValue2}}</span>

					</template>
				</el-table-column>
				<el-table-column prop="Price" label="价格"></el-table-column>
				<el-table-column prop="Stock" label="库存"></el-table-column>

			</el-table>
			<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
			 @current-change="handleSizeChange" :current-page="page.current" :page-sizes="[10,10, 20, 30, 40, 50]" :page-size="page.size"
			 layout="total, sizes, prev, pager, next, jumper" :total="page.total">
			</el-pagination>
		</div>
		<div slot="footer" class="dialog-footer">
			已选中
			<span style="color:#F56C6C">{{selectCount}}</span>
			件
			<el-button class="button" @click="saveSelectProList" style="margin-left:10px;" type="primary">确定</el-button>
		</div>
	</div>
</template>
<script>
	import {
		productSpecList,
		productBrandList,
		productGroupList,

	} from "@/api/goods";
	export default {
		name: 'selectProduce',
		props: {
			dataList: Array,
			ProductMixList: { //需要选中的商品列表
				type: Array,
				default: () => {
					return []
				}
			},
		},
		data() {
			return {
				searchKey: '',
				type: null,
				typeOptions: [{
						value: '单品销售',
						lable: '0'
					},
					{
						value: '组合销售',
						lable: '1'
					}
				],
				group: null,
				groupOptions: [],
				brand:null,
				brandOptions: [],
				page: {
					total: 0,
					current: 1,
					size: 10
				},
				tableDataList: [],
				selectCount: 0,
				checkedAll: false,
				multipleSelection: [],
				checkedShopPro: false,
				isReady:false,
			}
		},
		created() {
			this.getInfor();
			this.getSingleList()

		},
		mounted() {

		},
		watch: {
		},
		methods: {

			//筛选数据
			async getInfor() {
				try {
					let result = await productBrandList()
					this.brandOptions = result.Result

					let result1 = await productGroupList()
					this.groupOptions = result1.Result

				} catch (e) {
					console.log(e)
				} finally {

				}
			},


			async getSingleList() {
				this.loading = true;
				try {
					let data = {
						KeyWord: this.searchKey, // 搜索关键字，商品名称或条形码
						ProductGroupId: this.group,
						ProductBrandId: this.brand,
						Skip: (this.page.current - 1) * this.page.size, // 略过数据
						Take: this.page.size, // 取的数据
					};
					let result = await productSpecList(data);
					this.page.total = result.Result.Total;
					this.tableDataList = result.Result.Results;

				} catch (error) {
					console.log(error);
				} finally {
					
					if (this.ProductMixList.length&&(!this.isReady)&&this.$refs.compSelectProTable) {
						this.$refs.compSelectProTable.clearSelection();
						this.ProductMixList.map(item => {
							this.$refs['compSelectProTable'].toggleRowSelection(item, true);
						})
						this.isReady = true;
					}
					this.loading = false;
				}
			},
			//table原生全选按钮是否选中
			selectAll(e) {

			},
			//外部全选当前页
			selectCurrentAll(e) {
				this.$refs['compSelectProTable'].toggleAllSelection()
			},

			handleSelectionChange(val) {
				this.selectCount = val.length;
				this.multipleSelection = val;

			},
			// 切换显示条数
			handleSizeChange(val) {
				this.page.current = val;
				this.getSingleList()
			},
			// 翻页
			handleCurrentChange(val) {
				this.page.size = val;
				this.getSingleList()
			},

			clearSelection() {
				this.$nextTick(() => {
					this.$refs.compSelectProTable.clearSelection();
				});
			},
			getRowKeys(row) {
				return row.ProductSpecId;
			},
			handleFilter() {
				this.page.current = 1;
				this.getSingleList()
			},

			//关闭弹框，以及曝光选择的列表
			saveSelectProList() {
				if(!this.multipleSelection.length){
					this.$message({
						type: 'error',
						message: '请选择子商品!'
					});
					return
				}
				this.$emit('getSelectList', false, this.multipleSelection)
			}

		}
	}
</script>
<style lang="less">
	.contanierBox {
		position: relative;
		overflow: hidden;
		.dialog-footer {
			margin-top: 20px;
			text-align: center;
		
			.button {
				width: 150px;
			}
		}
	}
	.content {
		overflow: hidden;
	}
	
</style>
